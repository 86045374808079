<script setup lang="ts">
import { lgOptions, mobileModalOptions } from "./Detail.carouselOptions"
import { sm } from "~/utils/breakpoints"
import "swiper/css"
import "swiper/css/zoom"
import { Zoom } from "swiper/modules"
import { ModalDetailProps } from "./ModalDetail.props"

const props = withDefaults(defineProps<ModalDetailProps>(), {})

const swiperPropsDesktop = {
  ...lgOptions,
  allowTouchMove: false
}

const swiperPropsMobile = {
  ...mobileModalOptions,
  allowTouchMove: true,
  zoom: { maxRatio: 3 },
  modules: [Zoom]
}
const modalTablists = ref({
  tabsList: [
    {
      id: "videos",
      text: "Video"
    },
    {
      id: "images",
      text: "Immagini"
    }
  ]
})

const onPlayerReady = (player: any) => {
  player.play()
}
</script>

<template>
  <Dialog
    :open="isGalleryModalOpen"
    @close="toggleGalleryModal"
    class="relative z-50"
  >
    <div class="fixed inset-0 overflow-y-auto md:overflow-hidden">
      <DialogPanel class="min-h-screen w-full bg-white">
        <button
          :tabindex="sm ? '1' : '2'"
          @click="toggleGalleryModal"
          class="
            fixed
            right-4
            z-20
            outline-none
            md:right-[71px]
            md:top-6
            lg:right-[228px]
          "
          :class="{
            'top-4': !hasGalleryVideo,
            'top-20 z-40': hasGalleryVideo
          }"
        >
          <UtilsIcon
            name="Close.svg"
            class="h-10 w-10 bg-white bg-opacity-20 md:h-8 md:w-8"
          />
        </button>
        <TheTabs
          v-bind="modalTablists"
          tab-layout="start"
          :selectedIndex="activeTab === 'videos' ? 0 : 1"
          @change="changeTab"
          class="max-h-[100svh]"
          :class="{
            'md:padded h-[100svh] md:h-screen': hasGalleryVideo,
            'hidden-tabs-labels': !hasGalleryVideo
          }"
        >
          <template #tabs="{ selectedTab }">
            <div class="bg-white">
              <div
                class="
                  relative
                  flex
                  w-full
                  flex-col
                  justify-end
                  md:grid md:grid-cols-12
                  md:items-center
                  md:py-10
                "
                :class="{
                  'h-[calc(100svh-55px)] md:h-[calc(100svh-96px)]':
                    hasGalleryVideo,
                  'md:padded h-[100svh] md:h-screen': !hasGalleryVideo
                }"
              >
                <div class="relative h-full md:col-span-7 md:mb-0 md:h-auto">
                  <AppCarousel
                    class="carousel-overflowed h-full select-none"
                    :swiper-props="sm ? swiperPropsMobile : swiperPropsDesktop"
                    :items="selectedTab === 1 ? imagesItems : videosItems"
                    :key="sm ? 1 : 0"
                    @slideChange="modalSlideChanged"
                    @swiper="onModalSwiper"
                  >
                    <template #default="{ item }">
                      <UtilsWrapperImage
                        v-if="selectedTab === 1 && !item.isVideo"
                        class="
                          modal-main-image
                          wrapper-image-fit-contain
                          max-h-full
                          border-0
                          md:block
                          md:max-h-[70svh]
                        "
                        :="item"
                        sizes="sm:400px md:800px lg:1000px xl:1200px"
                        :class="{
                          'swiper-zoom-container': sm
                        }"
                      />
                      <div
                        class="flex h-full items-center md:block"
                        v-if="selectedTab === 0 && item.isVideo"
                      >
                        <UtilsVideo
                          :appVideoClasses="'w-full'"
                          :mobileVideoSrc="item?.video?.mobileVideoSrc"
                          :videoSrc="item?.video?.videoSrc"
                          :is-portrait-in-mobile="true"
                          :hasAutoplay="true"
                          :vimeoId="item?.video?.vimeoId"
                          :youtubeId="item?.video?.youtubeId"
                          :onPlayerReady="onPlayerReady"
                        ></UtilsVideo>
                      </div>
                    </template>
                  </AppCarousel>
                </div>

                <div
                  class="
                    relative
                    mt-6
                    flex
                    h-44
                    w-full
                    justify-center
                    md:col-span-5 md:col-start-9
                    md:m-0
                    md:flex
                    md:h-full
                    md:flex-col
                    md:justify-start
                    md:pt-28
                  "
                >
                  <div v-if="!sm">
                    <h1 v-if="title" class="beaver-bold md:dolphin-bold my-4">
                      {{ title }}
                    </h1>
                    <p v-if="description" class="beaver mb-12 text-black-80">
                      {{ description }}
                    </p>
                  </div>
                  <div
                    class="
                      flex
                      items-start
                      gap-3
                      overflow-x-auto
                      pr-10
                      scrollbar-hide
                      md:flex-wrap
                      md:pr-0
                    "
                  >
                    <div
                      class="relative"
                      v-for="(img, index) in selectedTab === 1
                        ? imagesItems
                        : videosItems"
                    >
                      <UtilsWrapperImage
                        v-if="selectedTab === 1"
                        :="img"
                        :key="index"
                        class="
                          wrapper-image-fit-contain wrapper-image-fixed-76
                          modal-image-thumbnail
                          ml-3
                          h-[78px]
                          w-[78px]
                          shrink-0
                          transform-gpu
                          snap-center
                          shadow-02
                          outline-none
                          md:ml-0
                        "
                        :class="{
                          'border border-[var(--selectableCard-border-color)]':
                            index === modalActiveIndex
                        }"
                        @click="clickModalThumbModalCarousel(index)"
                        :tabindex="sm ? '2' : '1'"
                        sizes="sm:100vw md:100vw lg:100px xl:100px"
                      />
                      <UtilsWrapperImage
                        v-if="selectedTab === 0"
                        :="img?.preview"
                        :key="index"
                        class="
                          wrapper-image-fixed-76
                          modal-image-thumbnail
                          ml-3
                          h-[78px]
                          w-[78px]
                          shrink-0
                          transform-gpu
                          snap-center
                          shadow-02
                          outline-none
                          md:ml-0
                        "
                        :class="{
                          'border border-[var(--selectableCard-border-color)]':
                            index === modalActiveIndex
                        }"
                        @click="clickModalThumbModalCarousel(index)"
                        :tabindex="sm ? '2' : '1'"
                        sizes="sm:100vw md:100vw lg:100px xl:100px"
                      />
                      <UtilsCarouselPlayerIcon
                        v-if="selectedTab === 0 && img?.isVideo"
                        :iconColor="img?.playerIcon?.color"
                        wrapperIconClasses="
                          absolute
                          left-0
                          top-0
                          z-10
                          ml-1.5
                          flex
                          h-full
                          w-full
                          items-center
                          justify-center
                          md:ml-0"
                        @click="clickModalThumbModalCarousel(index)"
                      ></UtilsCarouselPlayerIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </TheTabs>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">

:deep {
  .the_tabs-labels {
    @apply h-14 md:h-24 md:pt-10;
  }
}
.hidden-tabs-labels {
  :deep {
    .the_tabs-labels {
      @apply hidden;
    }
  }
}

:deep {
  .modal-main-image {
    picture {
      @apply max-h-full;
    }
  }
}

</style>
