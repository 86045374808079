<script setup lang="ts">
import type { GA4Entities, GA4Events } from "nuxt-ga4"
import { useGAEvent } from "#imports"
import { ProductDetailsProps } from "~/components/Pages/ProductDetails.props"
import { addToCartPosition } from "~/utils/constants"
import { productUtils } from "~/utils/product"
import type { AggregateRating, Review } from "schema-dts"
import { PRODUCT_PAGE, TApiErrorType } from "./p-[product].const"
import { formatProduct } from "~/utils/analytics/analytics"
import consola from "consola"

const { t } = useI18n()
const route = useRoute()
const { locale } = useI18n()
const { $cl } = useNuxtApp()
const { addToCart } = useCart()
const { sendEventAddToCart } = useGA4SiteEvents()

const requestId = route.path + "_" + Date.now()

const showAddToCartError = ref(false)
const notificationAlert = ref({ ...PRODUCT_PAGE.addToCart.defaultAlertProps })

const productSEO = computed(() => productData.value?.seo)

onUnmounted(() => {
  clearNuxtState("skuDataState")
})

interface FeedatyProductReviews {
  averageReviews: {
    Data: {
      Merchant: {
        AverageValue: number
        RatingsCount: number
      }
      Products: {
        AverageValue: number
        RatingsCount: number
      }
    }
  }
}

process.server &&
  consola.info(
    new Date().toISOString(),
    "Product page rendering start",
    route.path,
    requestId
  )

process.server &&
  consola.info(
    new Date().toISOString(),
    "Product page Gluon start",
    route.path,
    requestId
  )

const { data: productData, error } = await usePage(
  `/api/gluon/fetchProductPage/${locale.value}/${
    route.params.product as string
  }/product.js`
)

process.server &&
  consola.info(
    new Date().toISOString(),
    "Product page Gluon end",
    route.path,
    requestId
  )

// Get reviews
let reviews:
  | {
      aggregateRating?: AggregateRating
      review: Review[]
    }
  | undefined = undefined

if (process.server) {
  const { data: cachedReviews, error } = await useFetch(
    `/api/feedaty/getCachedReviews/${productData.value?.page?.details?.productId}`,
    {
      timeout: 1000,
      transform: (res: FeedatyProductReviews) => {
        let reviews: {
          aggregateRating?: AggregateRating
          review: Review[]
        } =
          (res?.averageReviews?.Data?.Products?.AverageValue ?? 0) > 0 &&
          (res?.averageReviews?.Data?.Products?.RatingsCount ?? 0) > 0
            ? {
                aggregateRating: res.averageReviews
                  ? {
                      "@type": "AggregateRating",
                      ratingValue:
                        res?.averageReviews?.Data?.Products?.AverageValue,
                      reviewCount:
                        res?.averageReviews?.Data?.Products?.RatingsCount
                    }
                  : {
                      "@type": "AggregateRating",
                      ratingValue: "n.a.",
                      reviewCount: 0
                    },
                review: []
              }
            : {
                review: []
              }
        return reviews
      }
    }
  )
  if (error.value) {
    process.server &&
      consola.error(
        new Date().toISOString(),
        "Product page error",
        "Error loading Feedaty reviews",
        route.path,
        requestId,
        error.value
      )
  }
  reviews = cachedReviews?.value ?? undefined
}

const alternativeProducts = route.hash === "#alternativeProducts"

type SkuData = {
  id: string
  price: number
  oldPrice: number
  currency: string
  stockQuantity: number
}

const skuData = ref<SkuData | null>()
const expirationDate = ref<string | null>()

if (error.value) {
  process.server &&
    consola.error(
      new Date().toISOString(),
      "Product page error",
      "Error loading Gluon data",
      route.path,
      requestId,
      error.value
    )
  throw createError({
    message: error.value.message,
    statusCode: error.value.statusCode ?? 404
  })
}

process.server &&
  consola.info(
    new Date().toISOString(),
    "Product page Commercelayer start",
    route.path,
    requestId
  )

if (productData.value?.page?.details?.productId) {
  try {
    const [sku] = await $cl.skus.list({
      include: ["prices", "stock_items", "stock_items.reserved_stock"],
      fields: {
        prices: [
          "currency_code",
          "amount_cents",
          "amount_float",
          "compare_at_amount_cents",
          "compare_at_amount_float"
        ]
      },
      filters: { code_eq: productData.value?.page?.details?.productId }
    })

    if (
      !productUtils.isIndexableStatus(
        productData.value?.page?.sellabilityStatus
      ) &&
      !productUtils.calculateMultiStockLocationAvailability(sku) &&
      productData.value?.page?.sellabilityRedirectPath &&
      productData.value?.page?.sellabilityRedirectStatus
    ) {
      await navigateTo(productData.value.page.sellabilityRedirectPath, {
        redirectCode: productData.value.page.sellabilityRedirectStatus
      })
    }

    if (sku?.prices?.[0] && sku?.stock_items?.length) {
      skuData.value = {
        id: sku.id,
        price: sku.prices[0].amount_float ?? 0,
        oldPrice: sku.prices[0].compare_at_amount_float ?? 0,
        currency: sku.prices[0].currency_code ?? "EUR",
        stockQuantity: productUtils.calculateMultiStockLocationStock(sku)
      }

      if (process.server) {
        useState<SkuData | null | undefined>(
          "skuDataState",
          () => skuData.value
        )
      }
      expirationDate.value =
        sku?.stock_items?.[0]?.metadata?.earliest_expiration_date
    }
  } catch (err) {
    process.server &&
      consola.error(
        new Date().toISOString(),
        "Product page error",
        "Error loading Commercelayer information",
        route.path,
        requestId,
        err
      )
  }
}

process.server &&
  consola.info(
    new Date().toISOString(),
    "Product page Commercelayer end",
    route.path,
    requestId
  )

const dataWithPriceAndQuantity = computed(():
  | ProductDetailsProps
  | undefined => {
  if (!productData.value) return

  const serverSideSkuData = useState<SkuData | null | undefined>("skuDataState")

  return {
    ...productData.value?.page,
    details: {
      ...productData.value?.page?.details,
      skuId: skuData.value?.id ?? serverSideSkuData?.value?.id!,
      price: skuData.value?.price ?? serverSideSkuData?.value?.price!,
      oldPrice: skuData.value?.oldPrice ?? serverSideSkuData?.value?.oldPrice,
      stockQuantity:
        skuData.value?.stockQuantity ?? serverSideSkuData?.value?.stockQuantity,
      expirationDate: expirationDate.value ?? ""
    },
    description: {
      ...productData.value.page.description,
      infos: {
        ...productData.value.page.description.infos,
        dueDate: expirationDate.value ?? ""
      }
    },
    reviews,
    brand: productData?.value?.page?.brand
  }
})

const handleAddToCart = ({
  quantity,
  item
}: {
  quantity: number
  item: ProductDetailsProps
}) => {
  if (!skuData.value || !dataWithPriceAndQuantity.value) return
  const algoliaLastClickedProduct = localStorage?.getItem(
    "algolia-last-clicked-product"
  )
  const queryID =
    algoliaLastClickedProduct &&
    JSON.parse(algoliaLastClickedProduct).path === route.path
      ? JSON.parse(algoliaLastClickedProduct).queryID
      : ""

  addToCart(
    {
      sku_code: item.details.productId,
      metadata: {
        product_image: item?.details.gallery[0]!,
        slug: `/p-${item.details.slug}`,
        price: skuData.value.price,
        oldPrice: skuData.value.oldPrice,
        inStock: skuData.value.stockQuantity > 0,
        unitType: item.description?.infos?.unitOfMeasure ?? "",
        expirationDate: expirationDate.value ?? "",
        isDeductable: item.details.isDeductable ?? false,
        ga4Item: GAItem.value ?? {},
        afterSearch: {
          queryID
        }
      }
    },
    quantity
  )
    .then(() => {
      sendEventAddToCart(
        { ...GAItem.value, quantity },
        {
          currentPrice: GAItem.value.price * quantity,
          position: addToCartPosition.pdp
        }
      )
    })
    .catch((apiError) => {
      apiError
      if (apiError.status === PRODUCT_PAGE.addToCart.apiErrorCheck.statusCode) {
        if (apiError.errors.length > 0) {
          const error = apiError.errors.find(
            (e) => e.code === PRODUCT_PAGE.addToCart.apiErrorCheck.code
          )
          setErrorMessage(error)
          showAddToCartError.value = true
        }
      }
      throw apiError
    })
}

const setErrorMessage = (error: any) => {
  let message = ""

  if (error && error.meta) {
    switch (error.meta.error) {
      case TApiErrorType.lessThanOrEqualTo:
        message = t(
          `productPage.notificationAlerts.addToCardError.${error.meta.error}.message`
        )
        break

      case TApiErrorType.outOfStock:
        message = t(
          `productPage.notificationAlerts.addToCardError.${error.meta.error}.message`
        )
        break

      default:
        message = t(
          "productPage.notificationAlerts.addToCardError.generic_error.message"
        )
        break
    }
  } else {
    message = t(
      "productPage.notificationAlerts.addToCardError.generic_error.message"
    )
  }

  notificationAlert.value = {
    ...notificationAlert.value,
    notificationAlertText: message
  }
}

const getRemainingProductString = (meta: any): string => {
  const key =
    "productPage.notificationAlerts.addToCardError." +
    meta.error +
    "." +
    (meta.count === 1 ? "singleRemaining" : "multipleRemaining")
  return t(key, { remainingProduct: meta.count })
}

const productCode = computed(() => {
  if (!dataWithPriceAndQuantity.value) return
  return dataWithPriceAndQuantity.value?.details?.productId ?? ""
})

/** get event event_select_item_{productCode} */
const { event: selectItemEvent } = useGAEvent(
  `event_select_item_${productCode.value ?? ""}`,
  "custom_event"
)

/** Define single GA4 item */
const GAItem = computed((): GA4Entities["gaItem"] => {
  let itemList = ""
  const dataWithPriceAndQuantityValue = dataWithPriceAndQuantity?.value
  const dataWithPriceAndQuantityBreadcrumbs =
    dataWithPriceAndQuantityValue?.breadcrumbs
  const dataWithPriceAndQuantityDetails = dataWithPriceAndQuantityValue?.details

  if (Object.keys(selectItemEvent.value).length) {
    itemList = selectItemEvent.value?.ecommerce?.items?.[0].item_list_name ?? ""
  }

  return {
    item_id: dataWithPriceAndQuantityValue?.details?.productId ?? "",
    item_name: dataWithPriceAndQuantityValue?.details?.title ?? "",
    index: 0,
    price: skuData.value?.price
      ? parseFloat(skuData?.value?.price.toString())
      : "",
    item_list_name: itemList,
    item_brand: dataWithPriceAndQuantityValue?.brand?.brandName ?? "",
    item_category: dataWithPriceAndQuantityBreadcrumbs?.links[0]?.label,
    item_category2: dataWithPriceAndQuantityBreadcrumbs?.links[1]?.label,
    item_category3: dataWithPriceAndQuantityBreadcrumbs?.links[2]?.label,
    item_category4: dataWithPriceAndQuantityBreadcrumbs?.links[3]?.label,
    item_category5: dataWithPriceAndQuantityBreadcrumbs?.links[4]?.label,
    currency: "EUR",
    discount:
      dataWithPriceAndQuantityDetails?.oldPrice &&
      dataWithPriceAndQuantityDetails?.price
        ? dataWithPriceAndQuantityDetails.oldPrice -
          dataWithPriceAndQuantityDetails.price
        : "",
    available:
      dataWithPriceAndQuantityDetails?.stockQuantity &&
      dataWithPriceAndQuantityDetails?.stockQuantity > 0
        ? "si"
        : "no",
    quantity: 1
  }
})

/** Define view_item payload event */
const viewItemEventPayload = computed((): GA4Events["custom_event"] => {
  return {
    event: "view_item",
    action: "view_item",
    ecommerce: {
      value: dataWithPriceAndQuantity.value?.details?.price
        ? dataWithPriceAndQuantity?.value?.details?.price
        : "",
      currency: "EUR",
      items: [formatProduct(GAItem.value)]
    }
  }
})

const closeAlert = () => {
  showAddToCartError.value = false
}
</script>

<template>
  <div>
    <SeoBase v-bind="productSEO" />
    <div class="fixed z-50 w-full p-4" v-if="showAddToCartError">
      <NotificationAlert v-bind="notificationAlert" @onClose="closeAlert()" />
    </div>
    <GAEvent
      :eventKey="(`event_view_item_${productCode ?? ''}` as string)"
      event="custom_event"
      :payload="viewItemEventPayload"
    >
      <PagesProductDetails
        v-if="dataWithPriceAndQuantity"
        v-bind="dataWithPriceAndQuantity"
        :scroll-to-alternative-products="alternativeProducts"
        @onPurchase="
          handleAddToCart({ quantity: $event, item: dataWithPriceAndQuantity })
        "
      />
    </GAEvent>
  </div>
</template>
